import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { confirmAlert } from '../utils/react-confirm-alert';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {base} from "../../base";

class AgeVerification extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            loading: true,
            redirect: false,
            modal: true,
            verifiedAge: false,
            user_age: props.appStorage.getItem('verifiedAge') || false
        }
    }

    componentDidMount() {
        this.props.loadPage();
    }

    handleChange (evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    submit_age_verification(age_to_verify){
        const year = this.state.year;
        const month = this.state.month;
        const day = this.state.day;
        const legalAge = parseInt(age_to_verify, 10);
        const tenantVariables = this.props.variables || {};
        this.setState({
            modal: false
        })
        if(!year || !month || !day){
            confirmAlert({
                variables: tenantVariables,
                title: "Input Error",
                message: "Please enter all inputs",
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                            this.setState({
                                modal:true
                            })
                        }
                    }
                ]
            })
            return;
        }
        var bday = [
            parseInt(year, 10),
            parseInt(month, 10) || 1,
            parseInt(day, 10) || 1
        ].join('/')
        var age = ~~((new Date().getTime() - +new Date(bday)) / (31557600000))

        if (age >= legalAge) {
            try {
                this.props.appStorage.setItem('birthday', bday);
                this.props.appStorage.setItem('verifiedAge', age);
            } catch(e){
                console.log(e)
                alert("Error: there was a problem saving your information.  Please make sure there is enough space on your device")
                return
            }
            if(this.props.appStorage.getItem('userEmail') || this.state.userEmail){
                const email = this.props.appStorage.getItem('userEmail') || this.state.userEmail;
                const userEmailToBase64 = btoa(email);
                base.post('users/'+userEmailToBase64+'/birthday' , {
                    data:bday,
                    then(err){
                        console.log(err)
                    }
                })
            }
        } else {
            confirmAlert({
                variables: tenantVariables,
                title: '',
                message: "We’re sorry. We are unable to process your submission at this time.",
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                            this.setState({
                                modal:true
                            })
                        }
                    }
                ]
            })
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        const verifiedAge = this.props.checkForAgeGate(tenantVariables);
        if (verifiedAge) {
            return (
                <Navigate to="/"/>
            )
        }

        const primaryColor = tenantVariables.primaryColor || '#000'
        const secondaryColor = tenantVariables.secondaryColor || '#000'
        const submitButtonText = "Submit";

        return (
            <div className="main-container-login" style={{backgroundColor:"black"}}>
                <Modal isOpen={this.state.modal} style={{width: '90%', textAlign: "center"}} id="ageGateModal">
                    <ModalHeader style={{color: secondaryColor, marginBottom: '10px'}}>Confirm Your Age</ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <form id="user-values-form">
                                    <div className="input-group">
                                        <div className="col-md-4 col-sm-12">
                                            <input id="month" name="month" type="number" className="form-control" value={this.state.month} onChange={this.handleChange} placeholder="MM" min='1' max='12'/>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <input id="day" name="day" type="number" className="form-control" value={this.state.day} onChange={this.handleChange} placeholder="DD" min='1' max='31'/>
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <input id="year" name="year" type="number" className="form-control" value={this.state.year} onChange={this.handleChange} placeholder="YYYY" min="1900" max="2019"/>
                                        </div>
                                    </div>
                                    <button type="button" className="btn btn-default btn-admin" onClick={() => { this.submit_age_verification(variable_age) }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'15px', fontSize:'1.3 rem'}}>{submitButtonText}</button>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default AgeVerification;
